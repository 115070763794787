import { useSupabase } from "@/hooks/useSupabase";
import { ICommentV2 } from "@/lib/services/chat.supbase.service";
import { PostCommentModal } from "@/ui/Components/PostCommentModal";
import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatActionBar } from "./ChatActionBar";
import { ChatContentItem } from "./ChatItemContent";

export const ChatContainerContent: FC<{ topic: string }> = ({ topic }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState<ICommentV2[]>([]);
  const [replyTo, setReplyTo] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const {
    authService: { chat: chatService },
    publicService: { chat: publicChatService },
    user,
  } = useSupabase();
  const postCommentModalDisclosure = useDisclosure();

  const loadComments = useCallback(
    async (isInitialLoad = false) => {
      if (loading || (!isInitialLoad && !hasMore)) return;

      const service = chatService || publicChatService;
      if (!service) return;

      setLoading(true);
      const offset = isInitialLoad ? 0 : comments.length;
      const newComments = await service.getCommentsV2(topic, 10, offset);

      if (newComments.length === 0) {
        setHasMore(false);
      } else {
        setComments((prev) =>
          isInitialLoad
            ? newComments
            : [
                ...prev,
                ...newComments.filter(
                  (comment) => !prev.some((c) => c.id === comment.id)
                ),
              ]
        );
      }

      setLoading(false);
    },
    [comments, hasMore, loading, chatService, publicChatService]
  );

  useEffect(() => {
    loadComments(true);
  }, [chatService, publicChatService]);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || loading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      loadComments();
    }
  }, [loading, hasMore, comments, chatService, publicChatService]);

  useEffect(() => {
    const ref = scrollRef.current;
    if (ref) ref.addEventListener("scroll", handleScroll);
    return () => {
      if (ref) ref.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleReply = (chat_id: string) => {
    setReplyTo(chat_id);
    postCommentModalDisclosure.onOpen();
  };

  const chatContent = useMemo(
    () => (
      <Flex
        align="stretch"
        flexDir="column"
        gap="8px"
        overflowY="auto"
        maxH={{ base: "700px", lg: "800px" }}
        pb="4"
        ref={scrollRef}
        scrollBehavior="smooth"
      >
        {comments.map((comment) => (
          <ChatContentItem
            key={`chat-content-item-${comment.id}`}
            comment={comment}
            onReply={handleReply}
          />
        ))}
        {loading && <Text>Loading...</Text>}
      </Flex>
    ),
    [comments, loading]
  );

  return (
    <Flex flexDir="column" gap="8px">
      <ChatActionBar
        direction="bottom"
        userId={user?.user_id}
        onPost={() => postCommentModalDisclosure.onOpen()}
        onDirectionClick={() =>
          scrollRef.current?.scrollTo(0, scrollRef.current.scrollHeight)
        }
      />
      <Flex minH="200px" flexDir="column">
        {chatContent}
      </Flex>
      <ChatActionBar
        direction="top"
        userId={user?.user_id}
        onPost={() => postCommentModalDisclosure.onOpen()}
        onDirectionClick={() => scrollRef.current?.scrollTo(0, 0)}
      />
      {postCommentModalDisclosure.isOpen && (
        <PostCommentModal
          topic={topic}
          onOk={() => {
            loadComments(true);
            postCommentModalDisclosure.onClose();
          }}
          {...postCommentModalDisclosure}
        />
      )}
    </Flex>
  );
};
