import { Flex, FlexProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

export const PageContent: FC<{ children: ReactNode } & FlexProps> = ({
  children,
  ...flexProps
}) => {
  return (
    <Flex
      mx="auto"
      gap="32px"
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
      py={{ base: "60px", lg: "100px" }}
      w={{ base: "full", xl: "1066px" }}
      px={{ base: "16px", lg: "24px", xl: "40px" }}
      {...flexProps}
    >
      {children}
    </Flex>
  );
};
